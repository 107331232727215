import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@components/accordion";
import EventAreaPicker from "@features/EventPage/components/eventAreaPicker";
import { Basket } from "@features/cartDetails/types";
import { FireIcon, LockClosedIcon } from "@heroicons/react/20/solid";
import { CalendarIcon } from "@heroicons/react/24/outline";
import {
  ExclamationTriangleIcon,
  FaceFrownIcon,
} from "@heroicons/react/24/solid";
import { EventData, EventShowing, EventShowingsMsgs } from "@types";
import { fetcher } from "@utils/fetchers";
import { useLatestCookieValue } from "@utils/hooks";
import { motion } from "framer-motion";
import useSWR from "swr";
import SkeletonLoading from "./components/skeletonLoading";
import { AccessType, ShowingStatus } from "@utils/enums";
import ShowingStatusToolTip from "./components/showingStatusToolTip";
import { joinClassNames } from "@utils/helpers";
import ShowingCountDown from "@features/EventPage/components/showingCountDown";
import { format, parseISO } from "date-fns";
import ConnectedShowings from "./components/connectedShowings";
import { useSearchParams } from "next/navigation";

interface Props {
  getPriceRange: ({
    pricesectionIds,
    groupedPricesectionIds,
  }: {
    pricesectionIds?: number[];
    groupedPricesectionIds?: number[];
  }) => { min: number; max: number; isEmpty?: boolean };
  event: EventData;
}

const EventListMode = ({ getPriceRange, event }: Props) => {
  const basketKey = useLatestCookieValue("ASP.NET_SessionId");
  const params = useSearchParams();
  const promo = params.get("promo");
  const eventId = event.legacy.e_id;
  const eventVenueId = event.legacy.ev_id;
  const {
    data: showingData,
    isLoading,
    mutate,
  } = useSWR<EventShowingsMsgs>(
    event.status === "published"
      ? `events/${eventId}/${eventVenueId}/showings${
          promo ? `?promoCode=${promo}` : ""
        }`
      : null,
    fetcher
  );

  const { data: basket } = useSWR<Basket>(
    basketKey ? `basket/${basketKey}` : null,
    fetcher
  );

  const basketEvent = basket?.Events?.find((event) => event.Id === eventId);

  const bookedShowingTickets = (id: number) =>
    basketEvent &&
    basketEvent.Showings.filter((showing) => showing.Id === id).map(
      (showing) => showing.Items.length
    )[0];

  const handleShowingType = (time: EventShowing) => {
    const messages = showingData?.messages;

    const titleShowing =
      messages
        ?.filter((msg) => msg.type === "button")
        .find((msg) => msg.status === time.status.id)?.message ?? "";

    const descriptionShowing =
      messages
        ?.filter((msg) => msg.type === "message")
        .find((msg) => msg.status === time.status.id)?.message ?? "";

    if (
      time.status.id === ShowingStatus.SOLDOUT ||
      time.status.id === ShowingStatus.ADMINSOLDOUT
    ) {
      return {
        bgColor: "bg-red-100",
        textColor: "text-red-600",
        title: titleShowing.trim() || "Sold out",
        description: descriptionShowing.trim() || "This showing is sold out.",
        selectable: false,
      };
    }

    if (time.status.id === ShowingStatus.CANCELLED) {
      return {
        bgColor: "bg-red-100",
        textColor: "text-red-600",
        title: titleShowing.trim() || "Cancelled",
        description: descriptionShowing.trim() || "This showing is cancelled.",
        selectable: false,
      };
    }

    if (time.status.id === ShowingStatus.POSTPONED) {
      return {
        bgColor: "bg-orange-100",
        textColor: "text-orange-600",
        title: titleShowing.trim() || "Postponed",
        description: descriptionShowing.trim() || "This showing is postponed.",
        selectable: false,
      };
    }

    if (time.status.id === ShowingStatus.NOTYETONSALE) {
      return {
        bgColor: "bg-orange-100",
        textColor: "text-orange-600",
        title: titleShowing.trim() || "Not on sale",
        description:
          descriptionShowing.trim() || "This showing is not yet on sale",
        selectable: false,
      };
    }

    if (time.status.id === ShowingStatus.AVAILABLE) {
      const title = messages
        ?.filter((msg) => msg.type === "button")
        .find(
          (msg) =>
            msg.access === time.access.type &&
            msg.status === ShowingStatus.AVAILABLE
        )?.message;

      const description = messages
        ?.filter((msg) => msg.type === "message")
        .find(
          (msg) =>
            msg.access === time.access.type &&
            msg.status === ShowingStatus.AVAILABLE
        )?.message;

      switch (time.access.type) {
        case AccessType.PUBLIC:
          return {
            bgColor: "bg-green-100",
            textColor: "text-green-600",
            selectable: true,
          };
        case AccessType.PASSWORD:
          return {
            bgColor: "bg-green-100",
            textColor: "text-green-600",
            title: title || "Password",
            description: description || "Password is required",
            selectable: true,
          };
        case AccessType.SALESCLOSED:
          return {
            bgColor: "bg-red-100",
            textColor: "text-red-600",
            title: title || "Sales closed.",
            description: description || "Sales closed.",
            selectable: false,
          };
        case AccessType.DOORSALES:
          return {
            bgColor: "bg-orange-100",
            textColor: "text-orange-600",
            title: title || "Door Sales",
            description: description || "Door sales may still be available.",
            selectable: false,
          };
        case AccessType.NOTYETONSALE:
          return {
            bgColor: "bg-orange-100",
            textColor: "text-orange-600",
            title: title
              ? title.replace(
                  "{date}",
                  time.access.date.public.local.start.formatted ??
                    format(
                      parseISO(time.access.date.public.local.start.raw),
                      `EEE d MMM yyyy h:mm aa`
                    )
                )
              : "Not on sale",
            description: description
              ? description.replace(
                  "{date}",
                  time.access.date.public.local.start.formatted ??
                    format(
                      parseISO(time.access.date.public.local.start.raw),
                      `EEE d MMM yyyy h:mm aa`
                    )
                )
              : "Not yet on sale",
            selectable: false,
          };
        case AccessType.EXPIRED:
          return {
            bgColor: "bg-red-100",
            textColor: "text-red-600",
            title: title || "Expired",
            description: description || "This ticket has expired.",
            selectable: false,
          };
      }
    }

    if (time.status.id === ShowingStatus.FREEENTRY) {
      return {
        bgColor: "bg-green-100",
        textColor: "text-green-600",
        title: titleShowing.trim() || "Free Entry",
        description:
          descriptionShowing.trim() ||
          "This is a free entry event. You do not need a ticket.",
        selectable: false,
      };
    }

    return {
      bgColor: "bg-indigo-100",
      textColor: "text-indigo-600",
      title: titleShowing.trim(),
      description: descriptionShowing.trim(),
      selectable: false,
    };
  };

  const activeShowings = showingData?.showings
    .filter((showing) => showing.active)
    .sort((a, b) => a.order - b.order);

  return activeShowings && activeShowings.length > 0 ? (
    <div className="flex flex-col gap-5 mt-10">
      {activeShowings.length > 1 ? (
        <h2 className="text-lg font-semibold text-black">Choose a date</h2>
      ) : (
        <></>
      )}
      <Accordion
        type="single"
        defaultValue={
          activeShowings.length === 1
            ? activeShowings[0].id.toString()
            : undefined
        }
        disabled={activeShowings.length === 1}
        collapsible={activeShowings.length > 1}
        asChild
        className="overflow-hidden sm:border max-sm:-mx-4 max-sm:-mb-4 bg-white shadow-sm sm:rounded-xl"
      >
        <motion.div
          data-testid="showinglistings"
          initial="hidden"
          animate="show"
          exit="hidden"
          variants={{
            hidden: {
              opacity: 0,
            },
            show: {
              opacity: 1,
              transition: {
                staggerChildren: 0.05,
              },
            },
          }}
        >
          {activeShowings.map((showing, index) => {
            const showing_type = handleShowingType(showing);

            if (!showing_type.selectable) {
              return (
                <motion.div
                  key={showing.id}
                  className={joinClassNames(
                    "relative flex flex-row items-center gap-4 bg-gray-100 px-4 sm:px-6 py-5 w-full",
                    index === 0
                      ? "max-sm:!border-t"
                      : index === activeShowings.length - 1
                      ? "!border-b-0"
                      : ""
                  )}
                  variants={{ hidden: { opacity: 0 }, show: { opacity: 1 } }}
                >
                  <div className="flex items-start gap-x-4 w-full">
                    <div className="relative flex items-center justify-center w-8 h-5 -mt-0.5">
                      <CalendarIcon className="h-6 w-6 flex-none rounded bg-transparent" />
                      {showing.status.id === ShowingStatus.SOLDOUT ||
                      showing.status.id === ShowingStatus.ADMINSOLDOUT ? (
                        <span
                          style={{ fontSize: "0.5rem" }}
                          className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 leading-none bg-red-400 text-white pt-0.5 pb-px px-0.5 w-max -rotate-6"
                        >
                          Sold Out
                        </span>
                      ) : showing.access.type === AccessType.PASSWORD ? (
                        <LockClosedIcon className="absolute -bottom-1 -right-1 h-4 w-4 stroke-2 text-yellow-500 bg-gray-100" />
                      ) : showing.status.hot ? (
                        <FireIcon className="absolute -bottom-1 -right-1 h-4 w-4 stroke-2 text-orange-400 bg-gray-100" />
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="flex flex-row items-start justify-between flex-wrap gap-x-4 gap-y-3.5 w-full">
                      <div className="text-left">
                        <p className="text-sm font-semibold text-black">
                          {showing.date.display
                            ? showing.date.display
                            : showing.date.local.formatted}
                        </p>
                        {showing.name ? (
                          <p className="mt-1 text-xs leading-5 text-gray-500 w-fit h-4">
                            {showing.name}
                          </p>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="h-5">
                        <div
                          className={joinClassNames(
                            "flex gap-1 items-center w-fit sm:w-auto py-1.5 px-2 rounded-md font-medium text-sm -mt-1.5 -ml-2",
                            showing_type.bgColor
                          )}
                        >
                          <span
                            className={joinClassNames(
                              "font-semibold",
                              showing_type.textColor
                            )}
                          >
                            {showing.status.id === ShowingStatus.AVAILABLE &&
                            showing.access.type === AccessType.NOTYETONSALE ? (
                              <ShowingCountDown
                                ttl={showing.access.date.public.secondstostart}
                                title={showing_type.title ?? ""}
                                mutate={mutate}
                                textColor={"text-orange-600"}
                                loadingColor={"#ea580c"}
                              />
                            ) : (
                              showing_type.title
                            )}
                          </span>
                          {showing_type.description?.trim() ? (
                            <ShowingStatusToolTip
                              statusId={showing.status.id}
                              accessType={showing.access.type}
                              title={showing_type.title ?? ""}
                              description={showing_type.description ?? ""}
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </motion.div>
              );
            }

            return (
              <AccordionItem
                asChild
                key={showing.id}
                value={showing.id.toString()}
                className={
                  index === 0
                    ? "max-sm:!border-t"
                    : index === activeShowings.length - 1
                    ? "!border-b-0"
                    : ""
                }
              >
                <motion.div
                  className="relative"
                  variants={{ hidden: { opacity: 0 }, show: { opacity: 1 } }}
                >
                  <AccordionTrigger
                    data-testid={`eventlist-${showing.id}`}
                    className={joinClassNames(
                      "relative group flex w-full justify-between gap-x-4 px-4 py-5 sm:enabled:hover:bg-gray-50 sm:px-6",
                      activeShowings.length > 1 ? "disabled:bg-gray-100" : ""
                    )}
                  >
                    <div className="relative flex flex-row w-full items-start justify-between gap-x-4">
                      <div className="flex items-start gap-x-4">
                        <div className="relative flex items-center justify-center w-8 -mt-0.5 h-5">
                          <CalendarIcon className="h-6 w-6 flex-none rounded bg-transparent" />
                          {showing.status.id === ShowingStatus.SOLDOUT ||
                          showing.status.id === ShowingStatus.ADMINSOLDOUT ? (
                            <span
                              style={{ fontSize: "0.5rem" }}
                              className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 leading-none bg-red-400 text-white pt-0.5 pb-px px-0.5 w-max -rotate-6"
                            >
                              Sold Out
                            </span>
                          ) : showing.access.type === AccessType.PASSWORD ? (
                            <LockClosedIcon className="absolute -bottom-1 -right-1 h-4 w-4 stroke-2 text-yellow-500 bg-white" />
                          ) : showing.status.hot ? (
                            <FireIcon className="absolute -bottom-1 -right-1 h-4 w-4 stroke-2 text-orange-400 bg-white group-hover:bg-gray-50" />
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="min-w-0 flex-auto text-left">
                          <p className="text-sm font-semibold text-black">
                            {showing.date.display
                              ? showing.date.display
                              : showing.date.local.formatted}
                          </p>
                          {showing.name ? (
                            <p className="mt-1 text-xs leading-5 h-4 text-gray-500 w-fit">
                              {showing.name}
                            </p>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      {bookedShowingTickets(showing.id) ? (
                        <span className="flex items-center justify-center p-1 px-2 rounded-lg bg-indigo-100 text-indigo-800 text-xs">
                          {bookedShowingTickets(showing.id)} in cart
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </AccordionTrigger>
                  <AccordionContent>
                    <div className="p-4">
                      {showing.config.showingcomboconfig &&
                      showing.config.showingcomboconfig.connectedShowings
                        .length >
                        showing.config.showingcomboconfig.minShowings ? (
                        <ConnectedShowings
                          showingcomboconfig={showing.config.showingcomboconfig}
                          activeShowings={activeShowings}
                        >
                          {(selectedConnectedShowings) => (
                            <EventAreaPicker
                              eventId={eventId}
                              eventName={event.name}
                              eventVenueId={eventVenueId}
                              orgUid={event.organisation.uid}
                              showing={showing}
                              messages={showingData?.messages ?? []}
                              basketEvent={basketEvent}
                              getPriceRange={getPriceRange}
                              selectedConnectedShowings={
                                selectedConnectedShowings
                              }
                              pricesection={
                                event.pricing.priceschemes?.find(
                                  (ps) =>
                                    ps.id === showing.config.pricescheme.id
                                )?.pricesections
                              }
                              analyticsConfigs={event.analyticsConfigs ?? []}
                              landingPages={event.landingpages}
                            />
                          )}
                        </ConnectedShowings>
                      ) : (
                        <EventAreaPicker
                          eventId={eventId}
                          eventName={event.name}
                          eventVenueId={eventVenueId}
                          orgUid={event.organisation.uid}
                          showing={showing}
                          messages={showingData?.messages ?? []}
                          basketEvent={basketEvent}
                          getPriceRange={getPriceRange}
                          pricesection={
                            event.pricing.priceschemes?.find(
                              (ps) => ps.id === showing.config.pricescheme.id
                            )?.pricesections
                          }
                          selectedConnectedShowings={null}
                          analyticsConfigs={event.analyticsConfigs ?? []}
                          landingPages={event.landingpages}
                        />
                      )}
                    </div>
                  </AccordionContent>
                </motion.div>
              </AccordionItem>
            );
          })}
        </motion.div>
      </Accordion>
    </div>
  ) : isLoading ? (
    <SkeletonLoading />
  ) : activeShowings && activeShowings.length === 0 ? (
    <div className="mt-4 sm:mt-8 border bg-white border-gray-200 rounded-lg overflow-hidden relative">
      <div className="flex flex-col items-center justify-center w-full h-[197px]">
        <div className="relative">
          <h2 className="relative z-10 font-black text-4xl text-black">
            Oh no!
          </h2>
          <ExclamationTriangleIcon className="absolute -top-16 -left-16 text-red-400 w-24 h-24 -rotate-12" />
        </div>
        <div className="relative mx-4">
          <h3 className="relative z-10 font-bold text-2xl text-black text-center">
            Looks like there are currently no available dates with tickets.
          </h3>
          <FaceFrownIcon className="absolute -bottom-12 -right-8 sm:-right-[4.25rem] text-[#F9CF47] w-24 h-24 rotate-[15deg]" />
        </div>
        <p className="text-lg text-gray-500">Please try again shortly.</p>
      </div>
    </div>
  ) : (
    <div className="mt-4 sm:mt-8 border bg-white border-gray-200 rounded-lg overflow-hidden relative">
      <div className="flex flex-col items-center justify-center w-full h-[197px]">
        <div className="relative">
          <h2 className="relative z-10 font-black text-4xl text-black">
            Oh no!
          </h2>
          <ExclamationTriangleIcon className="absolute -top-16 -left-16 text-red-400 w-24 h-24 -rotate-12" />
        </div>
        <div className="relative mx-4">
          <h3 className="relative z-10 font-bold text-2xl text-black text-center">
            Looks like there are no dates currently available.
          </h3>
          <FaceFrownIcon className="absolute -bottom-12 -right-8 sm:-right-[4.25rem] text-[#F9CF47] w-24 h-24 rotate-[15deg]" />
        </div>
        <p className="text-lg text-gray-500">Please try again shortly.</p>
      </div>
    </div>
  );
};

export default EventListMode;
