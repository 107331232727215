import MobileSelect from "@components/dropdowns/mobileSelect";
import { joinClassNames, getCurrencyFormat } from "@utils/helpers";
import React, { useState } from "react";
import MobileFlexi from "./mobileFlexi";
import { AnimatePresence, motion } from "framer-motion";
import { SparklesIcon } from "@heroicons/react/24/solid";
import { ShoppingCartIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { PriceStatus } from "@utils/enums";
import { FormattedPrice } from "../types";

interface Props {
  price: FormattedPrice;
  putQtyInCart: (
    id: string,
    type: string,
    priceAgeId: number | null,
    priceSectionId: number | null,
    priceId: number | null,
    gaAreaId: number | null,
    qty: string,
    isCombo: boolean,
    price: number,
    comboId: number | null,
    isDynamic?: boolean
  ) => Promise<"success" | "failure">;
  selectedQuantities: { id: string; qty: number; qty_from_combo: number }[];
}

const MobileTicketDisplay = ({
  price,
  putQtyInCart,
  selectedQuantities,
}: Props) => {
  const [prevQuantity, setPrevQuantity] = useState<string>("0");
  const [priceLoading, setPriceLoading] = useState<{
    id: number;
    status: "loading" | "success" | "failure";
  } | null>(null);

  const selectedQty = selectedQuantities.find((sq) => sq.id === price.id);
  const comboQty = selectedQuantities.find(
    (sq) => sq.id === price.id
  )?.qty_from_combo;

  const option_array = [
    0,
    ...Array.from(
      {
        length:
          (price.bookingLimit ?? price.max) -
          price.min +
          (price.min === 0 ? 0 : 1) +
          (price.bookingLimit && price.bookingLimit < price.max && selectedQty
            ? selectedQty.qty
            : 0),
      },
      (_, index) => price.min + index + (price.min === 0 ? 1 : 0)
    ),
  ];

  if (
    price.priceMaximum !== null &&
    price.priceMaximum > 0 &&
    price.priceMinimum !== null &&
    price.priceMinimum > 0
  ) {
    return (
      <MobileFlexi
        price={price}
        priceLoading={priceLoading}
        quantity={selectedQty?.qty.toString()}
        prevQuantity={prevQuantity}
        onOptionSelect={async (qty, dyanmicPrice) => {
          setPriceLoading({
            id: price.priceAgeId
              ? price.priceAgeId
              : price.comboId
              ? price.comboId
              : 0,
            status: "loading",
          });

          const result = await putQtyInCart(
            price.id,
            price.type,
            price.priceAgeId,
            price.priceSectionId,
            price.priceId,
            price.gaAreaId,
            qty,
            price.isCombo,
            dyanmicPrice,
            price.comboId ?? 0,
            true
          );

          setPriceLoading({
            id: price.priceAgeId
              ? price.priceAgeId
              : price.comboId
              ? price.comboId
              : 0,
            status: result,
          });
          setTimeout(() => {
            setPriceLoading(null);
            setPrevQuantity(qty);
          }, 1000);
        }}
        options={option_array}
      />
    );
  }

  return (
    <MobileSelect
      description={price.info}
      defaultValue={selectedQty?.qty.toString()}
      onOptionSelect={async (qty) => {
        setPriceLoading({
          id: price.priceAgeId
            ? price.priceAgeId
            : price.comboId
            ? price.comboId
            : 0,
          status: "loading",
        });

        const result = await putQtyInCart(
          price.id,
          price.type,
          price.priceAgeId,
          price.priceSectionId,
          price.priceId,
          price.gaAreaId,
          qty,
          price.isCombo,
          price.price,
          price.comboId ?? 0
        );

        setPriceLoading({
          id: price.priceAgeId
            ? price.priceAgeId
            : price.comboId
            ? price.comboId
            : 0,
          status: result,
        });
        setTimeout(() => {
          setPriceLoading(null);
          setPrevQuantity(qty);
        }, 1000);
      }}
      options={option_array}
    >
      {() => (
        <motion.button
          className={joinClassNames(
            "relative flex flex-col gap-2 w-full px-4 py-2 ring-1 ring-inset ring-gray-200 shadow rounded-xl",
            priceLoading?.status === "loading" ? "overflow-hidden" : "",
            (price.priceAgeId &&
              priceLoading?.id === price.priceAgeId &&
              priceLoading.status === "loading") ||
              (price.comboId &&
                priceLoading?.id === price.comboId &&
                priceLoading.status === "loading") ||
              (price.isCombo
                ? price.max === 0 || price.max === price.bookedTickets
                : (price.status !== PriceStatus.AVAILABLE &&
                    selectedQty?.qty === 0) ||
                  (price.bookingLimit ?? price.max) < price.min)
              ? "bg-gray-100"
              : "bg-white"
          )}
          whileTap={{
            scale: 0.95,
          }}
          disabled={
            (price.priceAgeId && priceLoading?.id === price.priceAgeId) ||
            (price.comboId && priceLoading?.id === price.comboId) ||
            (price.isCombo
              ? price.max === 0 || price.max === price.bookedTickets
              : (price.status !== PriceStatus.AVAILABLE &&
                  selectedQty?.qty === 0) ||
                (price.bookingLimit ?? price.max) < price.min)
          }
        >
          <AnimatePresence>
            {priceLoading?.status === "loading" && (
              <motion.div
                className="absolute top-0 left-0 w-[200%] h-full"
                style={{
                  backgroundImage: `repeating-linear-gradient( 105deg,#9ca3af44,
                        #9ca3af44 7%,
                        #4b556344 7%,
                        #4b556344 10%
                      )`,
                }}
                initial={{ opacity: 0 }}
                animate={{ x: ["0%", "-50%"], opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                  repeat: Infinity,
                  ease: "linear",
                  duration: 3,
                  opacity: {
                    duration: 0.1,
                  },
                }}
              />
            )}
          </AnimatePresence>
          <AnimatePresence>
            {(priceLoading?.status === "success" ||
              priceLoading?.status === "failure") &&
              selectedQty && (
                <motion.div
                  className={joinClassNames(
                    "absolute top-0 left-0 z-10 flex items-center justify-center w-full h-full rounded-xl",
                    priceLoading.status === "success"
                      ? "bg-green-100"
                      : "bg-red-100"
                  )}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  {selectedQty.qty - parseInt(prevQuantity) > 0 &&
                  priceLoading.status === "success" ? (
                    <div className="flex items-center gap-2 text-green-800">
                      <ShoppingCartIcon className="h-7 w-7" />
                      <span className="text-lg font-semibold">
                        Added to cart
                      </span>
                    </div>
                  ) : selectedQty.qty - parseInt(prevQuantity) < 0 &&
                    priceLoading.status === "success" ? (
                    <div className="flex items-center gap-2 text-green-800">
                      <ShoppingCartIcon className="h-7 w-7" />
                      <span className="text-lg font-semibold">
                        Removed from cart
                      </span>
                    </div>
                  ) : (
                    <span className="text-lg font-semibold text-red-800">
                      Something went wrong
                    </span>
                  )}
                </motion.div>
              )}
          </AnimatePresence>
          <div className="flex flex-row items-start justify-between gap-2 w-full">
            <span className="text-left text-lg font-semibold">
              {price.type}
            </span>
            <span className="relative text-lg font-semibold text-indigo-500">
              {price.isCombo ? (
                <>
                  {price.price > price.discountPrice ? (
                    <span className="absolute -bottom-4 rounded-lg w-fit text-xs text-gray-400 font-semibold line-through">
                      {getCurrencyFormat(price.price)}
                    </span>
                  ) : (
                    <></>
                  )}
                  {getCurrencyFormat(price.discountPrice)}
                </>
              ) : (
                <> {getCurrencyFormat(price.price)}</>
              )}
            </span>
          </div>
          <div className="flex items-center gap-2 flex-wrap w-full">
            {price.isCombo ? (
              price.max === 0 ? (
                <span className="px-2 py-0.5 rounded-md w-fit text-xs bg-red-100 text-red-800 font-semibold">
                  Sold Out
                </span>
              ) : price.max === price.bookedTickets ? (
                <span className="px-2 py-0.5 rounded-md w-fit text-xs bg-red-100 text-red-800 font-semibold">
                  Max Bought
                </span>
              ) : null
            ) : (
              ((price.status !== PriceStatus.AVAILABLE &&
                selectedQty?.qty === 0) ||
                (price.bookingLimit ?? price.max) < price.min) && (
                <span className="px-2 py-0.5 rounded-md w-fit text-xs bg-red-100 text-red-800 font-semibold">
                  {price.status === PriceStatus.NOTAVAILABLE
                    ? "Not Available"
                    : price.status === PriceStatus.SOLDOUT
                    ? "Sold Out"
                    : price.status === PriceStatus.BOOKEDOUT
                    ? "Booked Out"
                    : "Limit Reached"}
                </span>
              )
            )}
            {price.isCombo ? (
              <span className="px-2 py-0.5 rounded-md w-fit text-xs bg-green-200 text-green-800 font-semibold">
                COMBO
              </span>
            ) : (
              <></>
            )}
            {price.areaName ? (
              <span className="px-2 py-0.5 rounded-md w-fit text-xs bg-sky-100 text-sky-800 font-semibold">
                {price.areaName}
              </span>
            ) : (
              <></>
            )}
            {price.priceSchemeName ? (
              <span className="px-2 py-0.5 rounded-md w-fit text-xs bg-cyan-100 text-cyan-800 font-semibold">
                {price.priceSchemeName}
              </span>
            ) : (
              <></>
            )}
            {price.priceSectionName ? (
              <span className="px-2 py-0.5 rounded-md w-fit text-xs bg-teal-100 text-teal-800 font-semibold">
                {price.priceSectionName}
              </span>
            ) : (
              <></>
            )}
            {(price.priceAgeStartDate || price.priceAgeEndDate) &&
            price.status !== PriceStatus.AVAILABLE ? (
              <span className="px-2 py-0.5 rounded-md w-fit text-xs bg-amber-100 text-amber-800 font-semibold">
                {`Available${
                  price.priceAgeStartDate
                    ? ` from ${price.priceAgeStartDate}`
                    : ""
                }${
                  price.priceAgeEndDate
                    ? ` ${price.priceAgeStartDate ? "to" : "until"} ${
                        price.priceAgeEndDate
                      }`
                    : ""
                }`}
              </span>
            ) : (
              <></>
            )}
          </div>
          <div
            className={joinClassNames(
              "absolute -top-2 -right-1.5 flex items-center gap-1 flex-wrap justify-end transition-opacity duration-500 ease-in-out ",
              priceLoading !== null ? "opacity-0" : "opacity-100"
            )}
          >
            <span
              className={joinClassNames(
                "flex items-center gap-1 rounded-lg px-2 py-0.5 bg-indigo-100 text-indigo-800 text-xs",
                selectedQty?.qty === 0 ? "hidden" : ""
              )}
            >
              {selectedQty?.qty} in cart
            </span>
            <span
              className={joinClassNames(
                "flex items-center gap-1 rounded-lg px-2 py-0.5 bg-green-100 text-green-800 text-xs",
                comboQty === 0 ? "hidden" : ""
              )}
            >
              +{comboQty} from combo
            </span>
          </div>
          <motion.div
            className="absolute -top-2 -right-1 z-20"
            animate={
              priceLoading?.status !== "loading"
                ? { scale: [0, 1, 1.2, 1, 0], rotate: [0, -3, -6, -9, -12] }
                : { scale: 0, rotate: 0 }
            }
            transition={{ duration: 1.5 }}
          >
            {priceLoading?.status === "success" ? (
              <SparklesIcon className="text-yellow-400 h-6 w-6" />
            ) : priceLoading?.status === "failure" ? (
              <XMarkIcon className="text-red-400 h-6 w-6" />
            ) : (
              <></>
            )}
          </motion.div>
          <motion.div
            className="absolute -bottom-2 -left-1 z-20"
            animate={
              priceLoading?.status !== "loading"
                ? { scale: [0, 1, 1.2, 1, 0], rotate: [0, -3, -6, -9, -12] }
                : { scale: 0, rotate: 0 }
            }
            transition={{ duration: 1.5 }}
          >
            {priceLoading?.status === "success" ? (
              <SparklesIcon className="text-yellow-400 h-6 w-6" />
            ) : priceLoading?.status === "failure" ? (
              <XMarkIcon className="text-red-400 h-6 w-6" />
            ) : (
              <></>
            )}
          </motion.div>
          {priceLoading && priceLoading.status !== "loading" ? (
            <>
              <motion.div
                className={joinClassNames(
                  "absolute -top-2 left-4 w-2.5 h-2.5 rounded-[2px] z-20",
                  priceLoading.status === "success"
                    ? "bg-emerald-200"
                    : "bg-red-200"
                )}
                initial={{ x: 0, y: 0, opacity: 0, rotate: 0 }}
                animate={{
                  x: [0, -4, -8, -14, -16, -18],
                  y: [0, -8, -10, -9, -5, 10],
                  opacity: [0, 1, 1, 1, 1, 0],
                  rotate: [-1, -3, -6, -9, -12],
                }}
                transition={{ duration: 1, delay: 0.1 }}
              />
              <motion.div
                className={joinClassNames(
                  "absolute -top-2 left-24 w-3.5 h-3.5 rounded z-20",
                  priceLoading.status === "success"
                    ? "bg-emerald-500"
                    : "bg-red-500"
                )}
                initial={{ x: 0, y: 0, scale: 0, rotate: 0 }}
                animate={{
                  x: [0, -2, -4, -5, -5],
                  y: [0, -2, -4, -5, -5],
                  scale: [0, 1, 1.2, 1, 0],
                  rotate: [0, -3, -6, -9, -12],
                }}
                transition={{ duration: 1.2 }}
              />
              <motion.div
                className={joinClassNames(
                  "absolute -top-4 right-20 w-5 h-5 rounded-[3px] z-20",
                  priceLoading.status === "success"
                    ? "bg-emerald-200"
                    : "bg-red-700"
                )}
                initial={{ x: 0, y: 0, scale: 0, rotate: 0 }}
                animate={{
                  x: [0, 2, 4, 5, 5],
                  y: [0, -2, -4, -5, -7, -7],
                  scale: [0, 1, 1.2, 1, 0],
                  rotate: [1, 6, 12, 18, 24],
                }}
                transition={{ duration: 1.5, delay: 0.2 }}
              />
              <motion.div
                className={joinClassNames(
                  "absolute -bottom-1 right-1/2 w-3.5 h-3.5 rounded-[3px] z-20",
                  priceLoading.status === "success"
                    ? "bg-emerald-500"
                    : "bg-red-500"
                )}
                initial={{ x: 0, y: 0, scale: 0, rotate: 0 }}
                animate={{
                  x: [0, -2, -4, -5, -5],
                  y: [0, 2, 4, 5, 7, 7],
                  scale: [0, 1, 1.2, 0.8, 0.4, 0],
                  rotate: [0, -3, -6, -9, -12],
                }}
                transition={{ duration: 1.5 }}
              />
              <motion.div
                className={joinClassNames(
                  "absolute -bottom-2 right-4 w-4 h-4 rounded-[3px] z-20",
                  priceLoading.status === "success"
                    ? "bg-emerald-500"
                    : "bg-red-500"
                )}
                initial={{ x: 0, y: 0, scale: 0, rotate: 0 }}
                animate={{
                  x: [0, 1, 2, 3, 3],
                  y: [0, 2, 4, 5, 5],
                  scale: [0, 1, 1.2, 1, 0],
                  rotate: [0, -3, -6, -9, -12],
                }}
                transition={{ duration: 1.5 }}
              />
            </>
          ) : (
            <></>
          )}
        </motion.button>
      )}
    </MobileSelect>
  );
};

export default MobileTicketDisplay;
