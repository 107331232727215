import LoadingSpinner from "@components/loadingSpinner";
import { BasketShowing } from "@features/cartDetails/types";
import {
  ArrowRightIcon,
  CheckIcon,
  SparklesIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import {
  AllocatedArea,
  Analytics,
  EventData,
  EventShowing,
  Pricesection,
} from "@types";
import {
  getCookieValue,
  getCurrencyFormat,
  handlePricesectionNameEqual,
  joinClassNames,
} from "@utils/helpers";
import axios from "axios";
import { AnimatePresence, motion } from "framer-motion";
import dynamic from "next/dynamic";
import { FormEvent, useEffect, useState } from "react";
import { AccessType, ShowingGroupMode } from "@utils/enums";
import { useIsInViewport, useWindowSize } from "@utils/hooks";
import { ArrowPathIcon, LockClosedIcon } from "@heroicons/react/24/outline";
import EventToolTip from "./eventToolTip";
import EventDisplayGA from "@features/eventDisplayGA";
import { BASE_URL, COUNTRY_CODE } from "@utils/constants";
import { PriceStatus } from "@utils/enums";
import { groupBy } from "lodash";

const AllocatingMap = dynamic(() => import("@features/eventAllocatedMap"), {
  ssr: false,
});

interface Area {
  id: number;
  areaIds: number[];
  name: string;
  priceSections: {
    id: number;
    name: string;
    groupkey: string | null;
  }[];
  type: string;
  order: number;
  status: number;
}

interface Props {
  showing: EventShowing;
  eventId: number;
  eventName: string;
  eventVenueId: number;
  orgUid: string;
  basketEvent:
    | {
        Id: number;
        PromoterId: number;
        PromoterName: string;
        PortraitImageUrl: string;
        Url: string;
        Name: string;
        GenreId: number;
        GenreName: string;
        Showings: BasketShowing[];
      }
    | undefined;
  messages: {
    id: number;
    name: string;
    access: string;
    type: string;
    status: number;
    message: string;
  }[];
  getPriceRange: ({
    pricesectionIds,
    groupedPricesectionIds,
  }: {
    pricesectionIds?: number[];
    groupedPricesectionIds?: number[];
  }) => { min: number; max: number; isEmpty?: boolean };
  selectedConnectedShowings: number[] | null;
  pricesection: Pricesection[] | undefined;
  analyticsConfigs?: Analytics[];
  landingPages: EventData["landingpages"];
}

export default function EventAreaPicker({
  eventId,
  eventName,
  orgUid,
  showing,
  eventVenueId,
  basketEvent,
  getPriceRange,
  messages,
  selectedConnectedShowings,
  pricesection,
  analyticsConfigs,
  landingPages,
}: Props) {
  const availableAreas = showing.area;
  const showingId = showing.id ?? 0;
  const { targetRef, scrollToTarget } = useIsInViewport<HTMLHeadingElement>();
  const windowSize = useWindowSize();
  const [showingPassword, setShowingPassword] = useState(
    sessionStorage.getItem(`itk_${showing.id}_password`)
  );
  const [selectedPriceSectionIds, setSelectedPriceSectionIds] = useState<
    number[] | null
  >(null);

  const [passwordStatus, setPasswordStatus] = useState<
    "loading" | "success" | "failure" | "complete" | null
  >(null);

  async function checkShowingPassword(password: string) {
    setPasswordStatus("loading");
    const basketKey = getCookieValue("ASP.NET_SessionId");

    if (!basketKey) {
      const session_params = getCookieValue("itk_sessionparams");
      await fetch(`/api/basket`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(session_params ?? ""),
      });
    }

    const newBasketKey = basketKey || getCookieValue("ASP.NET_SessionId");

    const response = await axios
      .get(
        `${BASE_URL}/legacy/${COUNTRY_CODE}/shop/events/${eventId}/${eventVenueId}/showings/${showingId}/tickets/ga?password=${password}`,
        {
          headers: {
            "basket-key": newBasketKey,
            "itk-website-uid": process.env.NEXT_PUBLIC_ITK_WEBSITE_UID,
          },
        }
      )
      .catch(() => {
        return null;
      });

    setPasswordStatus(response ? "success" : "failure");
    if (!response) {
      document.getElementById("showing_password_input")?.focus();
      (
        document.getElementById("showing_password_input") as HTMLInputElement
      ).setSelectionRange(0, password.length);
    }
    if (response) {
      sessionStorage.setItem(`itk_${showing.id}_password`, password);
    }
    setTimeout(() => setPasswordStatus(response ? "complete" : null), 1000);
  }

  useEffect(() => {
    if (showing.access.type === AccessType.PASSWORD && showingPassword) {
      (
        document.getElementById("showing_password_form") as HTMLFormElement
      ).requestSubmit();
    }
  }, [showing.access.type, showingPassword]);

  const isPricesectionEqual = availableAreas
    ? handlePricesectionNameEqual(availableAreas)
    : false;

  function isAllocatedArea(obj: any): obj is AllocatedArea {
    return (
      obj.area !== undefined &&
      obj.pricesection !== undefined &&
      obj?.gaarea === undefined
    );
  }

  const setAreas = (): Area[] => {
    if (!availableAreas) return [];

    const combinedArray = [...availableAreas.ga, ...availableAreas.allocated];
    let groups: Record<string, Array<Area>> = {};

    const allocated_areas_obj = groupBy(
      availableAreas.allocated.filter((area) => !area.pricesection.groupkey),
      "area.name"
    );
    const ga_areas_obj = groupBy(
      availableAreas.ga.filter((area) => !area.pricesection.groupkey),
      "area.name"
    );

    const allocated_areas =
      showing.area.groupMode === ShowingGroupMode.GROUPED
        ? Object.keys(allocated_areas_obj).map((area_key) => ({
            id: allocated_areas_obj[area_key][0].area.id,
            areaIds: [allocated_areas_obj[area_key][0].area.id],
            name: area_key,
            priceSections: allocated_areas_obj[area_key].map(
              (a) => a.pricesection
            ),
            type: "allocated",
            order: allocated_areas_obj[area_key].reduce(
              (prev, curr) => (curr.order < prev ? curr.order : prev),
              999999
            ),
            status: PriceStatus.AVAILABLE,
          }))
        : availableAreas.allocated.map((area) => ({
            id: area.pricesection.id,
            areaIds: [area.area.id],
            name: `${
              area.area.name.toLowerCase() !== "general" &&
              area.area.name.toLowerCase() !== "allocated"
                ? area.area.name
                : ""
            } ${isPricesectionEqual ? "" : area.pricesection.name}`.trim(),
            priceSections: [area.pricesection],
            type: "allocated",
            order: area.order,
            status: PriceStatus.AVAILABLE,
          }));
    const ga_areas =
      showing.area.groupMode === ShowingGroupMode.GROUPED
        ? Object.keys(ga_areas_obj).map((area_key) => ({
            id: ga_areas_obj[area_key][0].area.id,
            areaIds: ga_areas_obj[area_key].map((area) => area.gaarea.id),
            name: area_key,
            priceSections: ga_areas_obj[area_key].map((a) => a.pricesection),
            type: "ga",
            order: ga_areas_obj[area_key].reduce(
              (prev, curr) => (curr.order < prev ? curr.order : prev),
              999999
            ),
            status: PriceStatus.AVAILABLE,
          }))
        : availableAreas.ga.map((area) => ({
            id: area.pricesection.id,
            areaIds: [area.gaarea.id],
            name: `${
              !area.pricesection.name.trim() ||
              (area.gaarea.name !== "GA" &&
                area.gaarea.name !== area.pricesection.name)
                ? `${
                    area.gaarea.name === "GA"
                      ? "General Admission"
                      : area.gaarea.name
                  }`
                : ""
            } ${isPricesectionEqual ? "" : area.pricesection.name}`,
            priceSections: [area.pricesection],
            type: "ga",
            order: area.order,
            status: area.pricesection.status,
          }));

    const ungrouped = [...allocated_areas, ...ga_areas];

    combinedArray.forEach((area) => {
      if (!area.pricesection.groupkey) return;
      if (groups[area.pricesection.groupkey]) {
        groups[area.pricesection.groupkey].push(
          isAllocatedArea(area)
            ? {
                id: area.pricesection.id,
                areaIds: [area.area.id],
                name: area.pricesection.name,
                priceSections: [area.pricesection],
                type: "allocated",
                order: area.order,
                status: PriceStatus.AVAILABLE,
              }
            : {
                id: area.pricesection.id,
                areaIds: [area.gaarea.id],
                name: area.gaarea.name,
                priceSections: [area.pricesection],
                type: "ga",
                order: area.order,
                status: area.pricesection.status,
              }
        );
        return;
      }

      groups = {
        ...groups,
        [area.pricesection.groupkey]: [
          isAllocatedArea(area)
            ? {
                id: area.pricesection.id,
                areaIds: [area.area.id],
                name: area.pricesection.name,
                priceSections: [area.pricesection],
                type: "allocated",
                order: area.order,
                status: PriceStatus.AVAILABLE,
              }
            : {
                id: area.pricesection.id,
                areaIds: [area.gaarea.id],
                name: area.gaarea.name,
                priceSections: [area.pricesection],
                type: "ga",
                order: area.order,
                status: area.pricesection.status,
              },
        ],
      };
    });

    const combined_groups: Area[] = Object.keys(groups).map((group) => ({
      id: groups[group].reduce(
        (prev, area) => prev + area.priceSections[0].id,
        0
      ),
      areaIds: groups[group].map((area) => area.areaIds[0]),
      name: group,
      priceSections: groups[group].map((area) => area.priceSections[0]),
      type: "ga",
      order: groups[group].reduce(
        (prev, area) => (area.order < prev ? area.order : prev),
        99999999999
      ),
      status: groups[group].reduce(
        (prev, area) => (area.status > prev ? area.status : prev),
        0
      ),
    }));

    return [...ungrouped, ...combined_groups].sort((a, b) => a.order - b.order);
  };

  const areas: Area[] = setAreas();

  const basketTickets = basketEvent?.Showings.find(
    (showing: any) => showing.Id === showingId
  )?.Items;

  const [selectedArea, setSelectedArea] = useState<Area | null>(
    areas.length === 1 ? areas[0] : null
  );

  const [showAddons, setShowAddons] = useState(
    areas.length === 0 && showing.area.gaHasExtras
  );

  function arraysEqualAfterSorting(arr1: number[], arr2: number[]): boolean {
    const sortedArr1 = arr1.slice().sort();
    const sortedArr2 = arr2.slice().sort();

    return (
      sortedArr1.length === sortedArr2.length &&
      sortedArr1.every((value, index) => value === sortedArr2[index])
    );
  }

  if (
    showing.access.type === AccessType.PASSWORD &&
    passwordStatus !== "complete"
  ) {
    const title = messages
      .filter((msg) => msg.type === "button")
      .find((msg) => msg.access === AccessType.PASSWORD)?.message;

    const description = messages
      .filter((msg) => msg.type === "message")
      .find((msg) => msg.access === AccessType.PASSWORD)?.message;

    return (
      <div className="max-sm:-mx-4 max-sm:-my-2 border bg-white border-gray-200 sm:rounded-lg overflow-hidden relative">
        <div className="relative z-10 flex flex-col items-center justify-center gap-1 w-full h-[197px] bg-white/40 backdrop-blur-3xl">
          <div className="flex items-center gap-1">
            <h2 className="font-black text-2xl text-black">
              {title ?? "Password Required"}
            </h2>
            <EventToolTip
              title={title ?? "Password Required"}
              description={description ?? "Please enter password"}
              icon={
                <LockClosedIcon className="absolute top-0 left-4 w-24 h-24 text-white/10 -rotate-12" />
              }
            />
          </div>
          <form
            id="showing_password_form"
            onSubmit={(e: FormEvent<HTMLFormElement>) => {
              e.preventDefault();
              const { showing_password } = Object.fromEntries(
                new FormData(e.currentTarget)
              );
              if (showing_password) {
                setShowingPassword(showing_password as string);
                checkShowingPassword(showing_password as string);
              }
            }}
          >
            <motion.div
              initial={{ x: 0 }}
              animate={
                passwordStatus === "failure"
                  ? { x: [-5, 5, -5, 5, -4, 4, -3, 3, 0] }
                  : { x: 0 }
              }
              transition={{ duration: 0.4 }}
              className={joinClassNames(
                "flex items-center gap-1 rounded-md border border-gray-300 px-2 py-1.5 bg-white text-black ring-2 focus-within:outline-none transition-[box-shadow] ease-in-out duration-200",
                passwordStatus === "failure"
                  ? "ring-red-500"
                  : "ring-transparent focus-within:ring-indigo-500"
              )}
            >
              <input
                id="showing_password_input"
                defaultValue={showingPassword ?? ""}
                name="showing_password"
                type="password"
                required
                className="h-8 focus-visible:outline-none"
              />
              <button
                type="submit"
                disabled={!!passwordStatus}
                className={joinClassNames(
                  "relative flex p-1 items-center justify-center rounded-full",
                  passwordStatus === "loading"
                    ? "bg-gray-200"
                    : passwordStatus === "success"
                    ? "bg-emerald-100"
                    : passwordStatus === "failure"
                    ? "bg-red-100"
                    : "bg-gray-100"
                )}
              >
                <AnimatePresence mode="wait">
                  <motion.div
                    key={passwordStatus}
                    initial={{ opacity: 0, width: "auto" }}
                    animate={{ opacity: 1, width: "auto" }}
                    exit={{ opacity: 0, width: "auto" }}
                    transition={{ duration: 0.15 }}
                  >
                    {passwordStatus === "loading" ? (
                      <LoadingSpinner size="24" classes="p-0.5" />
                    ) : passwordStatus === "success" ? (
                      <CheckIcon className="h-6 w-6 text-emerald-800" />
                    ) : passwordStatus === "failure" ? (
                      <XMarkIcon className="h-6 w-6 text-red-800" />
                    ) : (
                      <ArrowRightIcon className="h-6 w-6 text-gray-800" />
                    )}
                  </motion.div>
                </AnimatePresence>
                <motion.div
                  className="absolute top-0 right-0"
                  animate={
                    passwordStatus === "success"
                      ? {
                          scale: [0, 1, 1, 1, 0],
                          x: [0, 8, 14, 20, 22],
                          y: [0, -8, -14, -16, -14],
                          rotate: [0, 10, 20, 30, 40],
                        }
                      : { scale: 0, x: 0, y: 0, rotate: 0 }
                  }
                >
                  {passwordStatus === "success" ? (
                    <SparklesIcon className="text-yellow-400 h-4 w-4" />
                  ) : (
                    <></>
                  )}
                </motion.div>
                <motion.div
                  className="absolute bottom-0 -left-1"
                  animate={
                    passwordStatus === "success"
                      ? {
                          scale: [0, 1, 1, 1, 0],
                          x: [0, -8, -14, -20, -22],
                          y: [0, -4, -6, -2, 2],
                          rotate: [0, -10, -20, -30, -40],
                        }
                      : { scale: 0, x: 0, y: 0, rotate: 0 }
                  }
                >
                  {passwordStatus === "success" ? (
                    <SparklesIcon className="text-yellow-400 h-4 w-4" />
                  ) : (
                    <></>
                  )}
                </motion.div>
              </button>
            </motion.div>
          </form>
        </div>
        <span className="absolute top-0 right-8 w-14 h-32 rounded-full bg-yellow-400" />
        <span className="absolute top-8 right-12 w-32 h-14 rounded-full bg-fuchsia-400" />
        <span className="absolute bottom-2 right-8 w-32 h-14 rounded-full bg-sky-400" />
        <span className="absolute bottom-5 left-5 w-16 h-16 rounded-full bg-emerald-400" />
      </div>
    );
  }

  function calcQuantity(type: string, area?: Area) {
    if (!basketTickets) return 0;

    return basketTickets.reduce((prev, curr) => {
      const getpricesection = pricesection?.find(
        (ps) => ps.id === curr.PriceSectionId
      );

      const getpriceage = getpricesection?.priceages?.find(
        (pa) => pa.price.id === curr.PriceId
      );

      if (
        type === "standard" &&
        getpriceage?.type === type &&
        area?.priceSections
          .map((pricesection) => pricesection.id)
          .includes(curr.PriceSectionId)
      ) {
        return prev + 1;
      }

      if (type === "extra" && getpriceage?.type === type && getpricesection) {
        return prev + 1;
      }

      return prev;
    }, 0);
  }

  return (
    <>
      <div>
        <motion.h2
          initial={
            selectedArea || showAddons
              ? { opacity: 0, height: 0 }
              : { opacity: 1, height: 44 }
          }
          animate={
            selectedArea || showAddons
              ? { opacity: 0, height: 0 }
              : { opacity: 1, height: 44 }
          }
          className="text-lg font-semibold text-black"
        >
          Choose an area
        </motion.h2>
        <nav
          className="isolate grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-3"
          aria-label="areas"
        >
          {areas.map((area) => {
            const areaBasketQuantity = calcQuantity("standard", area);

            return (
              <button
                key={`${area.id}-${area.name}`}
                data-testid={`area-${area.id}`}
                className={joinClassNames(
                  showAddons ||
                    (selectedArea &&
                      (selectedArea.id !== area.id ||
                        !arraysEqualAfterSorting(
                          area.areaIds,
                          selectedArea?.areaIds ?? []
                        )))
                    ? "hidden sm:block"
                    : "block",
                  "relative rounded-md border shadow border-gray-100 transition-colors ease-in-out duration-200",
                  area.id === selectedArea?.id &&
                    arraysEqualAfterSorting(area.areaIds, selectedArea.areaIds)
                    ? "bg-indigo-50 ring-2 ring-indigo-500 cursor-default"
                    : area.status !== PriceStatus.AVAILABLE
                    ? "bg-gray-100 text-gray-700"
                    : "bg-white"
                )}
                aria-current={
                  selectedArea?.id === area.id &&
                  arraysEqualAfterSorting(area.areaIds, selectedArea.areaIds)
                    ? "page"
                    : undefined
                }
                onClick={() => {
                  setSelectedPriceSectionIds(null);

                  if (
                    selectedArea?.id === area.id &&
                    arraysEqualAfterSorting(
                      area.areaIds,
                      selectedArea.areaIds
                    ) &&
                    windowSize.width &&
                    windowSize.width > 640
                  ) {
                    return;
                  }

                  setSelectedArea(
                    selectedArea?.id === area.id &&
                      arraysEqualAfterSorting(
                        area.areaIds,
                        selectedArea.areaIds
                      ) &&
                      windowSize.width &&
                      windowSize.width <= 640
                      ? null
                      : area
                  );
                  setShowAddons(false);
                  if (selectedArea?.id !== area.id) {
                    scrollToTarget();
                  }
                }}
                disabled={
                  area.status !== PriceStatus.AVAILABLE ||
                  (areas.length === 1 && !showing.area.gaHasExtras)
                }
              >
                <div className="flex items-center justify-between gap-1 px-4 py-2">
                  <div className="flex flex-col overflow-hidden justify-center text-base font-medium text-black w-full">
                    <p className="text-left truncate">{area.name}</p>
                    <div className="max-sm:flex max-sm:items-center  max-sm:justify-between  max-sm:gap-2  max-sm:w-full">
                      <div className="flex flex-col">
                        <span className="text-sm text-indigo-500 w-fit">
                          {area.type === "ga"
                            ? "General Admission"
                            : "Allocated"}
                        </span>

                        <div className="flex items-center gap-2 justify-between w-full">
                          <span className="text-xs text-gray-500 w-fit">
                            {getPriceRange({
                              groupedPricesectionIds: area.priceSections.map(
                                (pricesection) => pricesection.id
                              ),
                            }).min !==
                            getPriceRange({
                              groupedPricesectionIds: area.priceSections.map(
                                (pricesection) => pricesection.id
                              ),
                            }).max ? (
                              <>
                                {getCurrencyFormat(
                                  getPriceRange({
                                    groupedPricesectionIds:
                                      area.priceSections.map(
                                        (pricesection) => pricesection.id
                                      ),
                                  }).min
                                )}{" "}
                                -{" "}
                              </>
                            ) : (
                              <></>
                            )}
                            {getCurrencyFormat(
                              getPriceRange({
                                groupedPricesectionIds: area.priceSections.map(
                                  (pricesection) => pricesection.id
                                ),
                              }).max
                            )}
                          </span>
                        </div>
                      </div>
                      {selectedArea?.id === area.id &&
                      arraysEqualAfterSorting(
                        area.areaIds,
                        selectedArea.areaIds
                      ) &&
                      ((!showAddons && areas.length > 1) ||
                        (areas.length === 1 && showing.area.gaHasExtras)) &&
                      windowSize.width &&
                      windowSize.width < 640 ? (
                        <div className="flex gap-1 items-center shrink-0">
                          <ArrowPathIcon className="h-4 w-4 text-gray-500" />
                          <span className="text-gray-500 text-sm font-medium">
                            Change area
                          </span>
                        </div>
                      ) : area.status !== PriceStatus.AVAILABLE ? (
                        <span className="px-2 py-0.5 rounded-lg w-fit text-sm bg-red-100 text-red-800 font-semibold">
                          {area.status === PriceStatus.NOTAVAILABLE
                            ? "Not Available"
                            : area.status === PriceStatus.SOLDOUT
                            ? "Sold Out"
                            : area.status === PriceStatus.BOOKEDOUT
                            ? "Booked Out"
                            : "Limit Reached"}
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                {areaBasketQuantity > 0 ? (
                  <span className="absolute -right-2 -top-2.5 block h-5 w-auto px-2 pt-0.5 rounded-lg bg-indigo-100 text-indigo-800 text-xs text-center">
                    {areaBasketQuantity} in cart
                  </span>
                ) : (
                  <></>
                )}
              </button>
            );
          })}
          {showing.area.gaHasExtras ? (
            <button
              data-testid={`area-addons`}
              className={joinClassNames(
                !showAddons && selectedArea ? "hidden sm:block" : "block",
                "relative rounded-md border shadow border-gray-100 transition-colors ease-in-out duration-200",
                showAddons
                  ? "bg-indigo-50 ring-2 ring-indigo-500 cursor-default"
                  : "bg-white"
              )}
              aria-current={showAddons ? "page" : undefined}
              onClick={() => {
                if (showAddons && windowSize.width && windowSize.width > 640) {
                  return;
                }
                setSelectedArea(null);
                setSelectedPriceSectionIds(null);

                setShowAddons(!showAddons);
                if (showAddons) {
                  scrollToTarget();
                }
              }}
            >
              <div className="flex items-center justify-between gap-1 px-4 py-2">
                <div className="flex flex-col overflow-hidden justify-center text-base font-medium text-black w-full">
                  <p className="text-left truncate ">Add ons</p>
                  <span className="text-sm text-indigo-500 w-fit">
                    Extra add ons
                  </span>

                  <div className="flex items-center gap-2 justify-between w-full">
                    {!getPriceRange({
                      pricesectionIds: availableAreas.ga.map(
                        (ga) => ga.pricesection.id
                      ),
                    }).isEmpty && (
                      <span className="text-xs text-gray-500 w-fit">
                        {getPriceRange({
                          pricesectionIds: availableAreas.ga.map(
                            (ga) => ga.pricesection.id
                          ),
                        }).min !==
                        getPriceRange({
                          pricesectionIds: availableAreas.ga.map(
                            (ga) => ga.pricesection.id
                          ),
                        }).max ? (
                          <>
                            {getCurrencyFormat(
                              getPriceRange({
                                pricesectionIds: availableAreas?.ga.map(
                                  (ga) => ga.pricesection.id
                                ),
                              }).min
                            )}{" "}
                            -{" "}
                          </>
                        ) : (
                          <></>
                        )}
                        {getCurrencyFormat(
                          getPriceRange({
                            pricesectionIds: availableAreas?.ga.map(
                              (ga) => ga.pricesection.id
                            ),
                          }).max
                        )}
                      </span>
                    )}
                  </div>
                </div>
                {showAddons && windowSize.width && windowSize.width < 640 ? (
                  <div className="flex gap-1 items-center shrink-0">
                    <ArrowPathIcon className="h-4 w-4 text-gray-500" />
                    <span className="text-gray-500 text-sm font-medium">
                      Change area
                    </span>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              {calcQuantity("extra") > 0 ? (
                <span className="absolute -right-2 -top-2.5 block h-5 w-auto px-2 pt-0.5 rounded-lg bg-indigo-100 text-indigo-800 text-xs text-center">
                  {calcQuantity("extra")} in cart
                </span>
              ) : (
                <></>
              )}
            </button>
          ) : (
            <></>
          )}
        </nav>
      </div>
      {selectedArea !== null &&
        !showAddons &&
        selectedArea.type === "allocated" &&
        selectedArea.priceSections.length > 1 && (
          <div>
            <h2 className="my-4 text-lg font-semibold text-black">
              Sections being shown
            </h2>
            <nav
              className="isolate grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-3"
              aria-label="areas"
            >
              <motion.button
                onClick={() => setSelectedPriceSectionIds(null)}
                whileTap={{
                  scale: 0.95,
                }}
                className={joinClassNames(
                  "relative px-4 py-2 rounded-md border shadow border-gray-100 transition-colors ease-in-out duration-200",
                  selectedPriceSectionIds === null ||
                    (selectedPriceSectionIds &&
                      selectedPriceSectionIds.length ===
                        selectedArea.priceSections.length)
                    ? "bg-indigo-50 ring-2 ring-indigo-500"
                    : "bg-white"
                )}
              >
                <span className="font-medium text-black">All sections</span>
              </motion.button>
              {selectedArea?.priceSections.map((section) => (
                <motion.button
                  key={section.id}
                  onClick={() => {
                    if (
                      selectedPriceSectionIds &&
                      selectedPriceSectionIds.includes(section.id)
                    ) {
                      setSelectedPriceSectionIds((prev) =>
                        (prev ?? []).filter((id) => id !== section.id)
                      );
                    } else {
                      if (
                        selectedPriceSectionIds &&
                        selectedPriceSectionIds.length ===
                          selectedArea.priceSections.length - 1
                      ) {
                        setSelectedPriceSectionIds(null);
                      } else {
                        setSelectedPriceSectionIds((prev) => [
                          ...(prev ?? []),
                          section.id,
                        ]);
                      }
                    }
                  }}
                  whileTap={{
                    scale: 0.95,
                  }}
                  className={joinClassNames(
                    "relative px-4 py-2 rounded-md border shadow border-gray-100 transition-colors ease-in-out duration-200",
                    selectedPriceSectionIds &&
                      selectedPriceSectionIds.includes(section.id) &&
                      selectedPriceSectionIds.length !==
                        selectedArea.priceSections.length
                      ? "bg-indigo-50 ring-2 ring-indigo-500"
                      : "bg-white"
                  )}
                >
                  <span className="font-medium text-black">{section.name}</span>
                </motion.button>
              ))}
            </nav>
          </div>
        )}
      <motion.h2
        ref={targetRef}
        initial={{ opacity: 0, height: 0, marginTop: 0 }}
        animate={
          selectedArea !== null && !showAddons
            ? { opacity: 1, height: 44, marginTop: 16 }
            : { opacity: 0, height: 0, marginTop: 0 }
        }
        className="text-lg font-semibold text-black truncate"
      >
        {selectedArea?.name ?? ""} Tickets
      </motion.h2>
      {selectedArea && !showAddons ? (
        selectedArea.type === "ga" ? (
          <EventDisplayGA
            key={selectedArea.id}
            eventId={eventId}
            eventName={eventName}
            eventVenueId={eventVenueId}
            orgUid={orgUid}
            showingId={showingId}
            gaAreaIds={selectedArea.areaIds}
            isExtraAddOns={showAddons}
            priceSectionIds={selectedArea.priceSections.map(
              (pricesection) => pricesection.id
            )}
            basketTickets={basketTickets ?? []}
            analyticsConfigs={analyticsConfigs}
            landingPages={landingPages}
          />
        ) : (
          <AllocatingMap
            eventId={eventId}
            eventName={eventName}
            eventVenueId={eventVenueId}
            orgUid={orgUid}
            areaId={selectedArea.areaIds[0]}
            priceSectionIds={
              showing.area.groupMode === ShowingGroupMode.GROUPED
                ? selectedPriceSectionIds
                : selectedArea.priceSections.map(
                    (pricesection) => pricesection.id
                  )
            }
            showingId={showingId}
            selectedConnectedShowings={selectedConnectedShowings}
            areaName={selectedArea.name}
            analyticsConfigs={analyticsConfigs}
            landingPages={landingPages}
          />
        )
      ) : (
        <></>
      )}
      {(selectedArea || showAddons) && showing.area.gaHasExtras ? (
        <>
          <h2 className="text-lg font-semibold text-black truncate h-11 mt-4">
            Extra Add Ons Tickets
          </h2>
          <EventDisplayGA
            eventId={eventId}
            eventName={eventName}
            eventVenueId={eventVenueId}
            orgUid={orgUid}
            showingId={showingId}
            gaAreaIds={availableAreas?.ga.map((ga) => ga.gaarea.id) ?? []}
            isExtraAddOns={true}
            priceSectionIds={
              availableAreas?.ga.map((ga) => ga.pricesection.id) ?? []
            }
            basketTickets={basketTickets ?? []}
            analyticsConfigs={analyticsConfigs}
            landingPages={landingPages}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
}
