import(/* webpackMode: "eager" */ "/home/runner/work/iticket-shopsite/iticket-shopsite/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/iticket-shopsite/iticket-shopsite/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/iticket-shopsite/iticket-shopsite/src/components/analyticsPage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/iticket-shopsite/iticket-shopsite/src/components/trackGTMPageView.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/iticket-shopsite/iticket-shopsite/src/features/EventPage/eventDetails.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/iticket-shopsite/iticket-shopsite/src/features/EventPage/eventInfo.tsx");
