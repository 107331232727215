"use client";

import {
  ExclamationTriangleIcon,
  FaceFrownIcon,
} from "@heroicons/react/24/solid";
import { getCookieValue } from "@utils/helpers";
import axios from "axios";
import useSWR, { mutate } from "swr";
import { GATicket } from "../EventPage/types";
import { TicketItem } from "@features/cartDetails/types";
import EventGASelection from "./components/eventGASelection";
import { BASE_URL, COUNTRY_CODE } from "@utils/constants";
import { useSearchParams } from "next/navigation";
import { useLatestCookieValue } from "@utils/hooks";
import { uniqBy } from "lodash";
import { Analytics, EventData } from "@types";

const customAxiosInstance = axios.create({ validateStatus: () => true });

const customFetcher = async ([url, params]: [string, string]) => {
  let basketKey = getCookieValue("ASP.NET_SessionId");

  if (!basketKey) {
    // If the cookie is not present, make a POST request to create it
    const res = await fetch(`/api/basket`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    });

    if (!res.ok) {
      return null;
    }

    // retrieve the cookie again
    basketKey = getCookieValue("ASP.NET_SessionId");
  }

  return customAxiosInstance
    .get(`${BASE_URL}/legacy/${COUNTRY_CODE}/shop/${url}`, {
      headers: {
        "basket-key": basketKey,
        "itk-website-uid": process.env.NEXT_PUBLIC_ITK_WEBSITE_UID,
      },
    })
    .then(async (res) => {
      if (res.status === 401) {
        await fetch(`/api/basket`, {
          method: "DELETE",
        });

        await fetch(`/api/basket`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(params),
        });

        await mutate(`${url}`);
        return null;
      }

      if (res.status !== 200) return null;
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

interface Props {
  eventId: number;
  eventName: string;
  eventVenueId: number;
  orgUid: string;
  showingId: number;
  gaAreaIds: number[];
  priceSectionIds: number[];
  isExtraAddOns?: boolean;
  basketTickets: TicketItem[];
  analyticsConfigs?: Analytics[];
  landingPages: EventData["landingpages"];
}

export default function DisplayGA({
  eventId,
  eventName,
  eventVenueId,
  orgUid,
  showingId,
  gaAreaIds,
  priceSectionIds,
  isExtraAddOns = false,
  basketTickets,
  analyticsConfigs,
  landingPages,
}: Props) {
  const session_params = useLatestCookieValue("itk_sessionparams");
  const params = useSearchParams();
  const promo = params.get("promo");
  const { data, isLoading } = useSWR<GATicket>(
    [
      `events/${eventId}/${eventVenueId}/showings/${showingId}/tickets/ga${
        promo ? `?promo=${promo}` : ""
      }`,
      session_params ?? "",
    ],
    customFetcher
  );

  const prices = uniqBy(
    data?.TicketOptions?.filter((to) =>
      isExtraAddOns
        ? to.IsExtra
        : to.GAAreaId
        ? gaAreaIds.includes(to.GAAreaId) &&
          priceSectionIds.includes(to.PriceSectionId) &&
          !to.IsExtra
        : priceSectionIds.includes(to.PriceSectionId) && !to.IsExtra
    ).map((to) => ({
      id: to.IsCombo
        ? `combo-${to.ComboId}`
        : `${to.GAAreaId}-${to.PriceSectionId}-${to.PriceAgeId}`,
      gaAreaId: to.GAAreaId,
      priceSectionId: to.PriceSectionId,
      priceAgeId: to.PriceAgeId,
      priceId: to.PriceId,
      type: to.Name,
      info: to.IsCombo ? to.Description : to.PriceAgeDescription,
      price: to.Price,
      discountPrice: to.DiscountPrice,
      min: to.MinTickets ?? 0,
      max: to.MaxTickets,
      minPrice: to.Price,
      maxPrice: to.Price,
      priceMinimum: to.PriceMinimum,
      priceMaximum: to.PriceMaximum,
      bookedTickets: to.IsCombo
        ? to.NumberOfTimesUsed
        : to.BookedTicketIds && to.BookedTicketIds.length > 0
        ? to.BookedTicketIds.filter((id) => id.ComboId === null).length
        : 0,
      bookedFromCombo:
        to.BookedTicketIds && to.BookedTicketIds.length > 0
          ? to.BookedTicketIds.filter((id) => id.ComboId !== null).length
          : 0,
      bookingLimit: to.BookingLimit,
      isCombo: to.IsCombo,
      status: to.AvailabilityStatus,
      comboId: to.ComboId,
      isExtra: to.IsExtra,
      isPromo: to.IsPromo,
      areaName: to.AreaName,
      priceSchemeName: to.PriceSchemeName ? to.PriceSchemeName.trim() : "",
      priceSectionName: to.PriceSectionName ? to.PriceSectionName.trim() : "",
      priceAgeStartDate: to.PriceAgeStartDate,
      priceAgeEndDate: to.PriceAgeEndDate,
    })),
    "id"
  );

  if (isLoading) {
    return (
      <>
        <div className="hidden sm:block max-sm:-mx-4 max-sm:-my-2 border bg-white border-gray-200 sm:rounded-lg overflow-hidden relative animate-pulse">
          <div className="w-full">
            <div className="flex w-full p-4 border-b border-gray-300">
              <h3 className="h-5 w-full bg-gray-200 rounded-md" />
            </div>
            <ul className="p-4 space-y-4">
              <li className="w-full h-4 bg-gray-200 rounded-md" />
              <li className="w-full h-4 bg-gray-200 rounded-md" />
              <li className="w-full h-4 bg-gray-200 rounded-md" />
              <li className="w-full h-4 bg-gray-200 rounded-md" />
            </ul>
          </div>
        </div>
        <div className="sm:hidden flex flex-col gap-4 px-2 pb-2">
          {Array.from({ length: 4 }).map((_, index) => (
            <div
              key={index}
              className="w-full  rounded-xl bg-gray-200 animate-pulse h-20"
            />
          ))}
        </div>
      </>
    );
  }

  if (!data) {
    return (
      <div className="max-sm:-mx-4 max-sm:-my-2 border bg-white border-gray-200 sm:rounded-lg overflow-hidden relative">
        <div className="flex flex-col items-center justify-center w-full h-[197px]">
          <div className="relative">
            <h2 className="relative z-10 font-black text-4xl text-black">
              Oh no!
            </h2>
            <ExclamationTriangleIcon className="absolute -top-16 -left-16 text-red-400 w-24 h-24 -rotate-12" />
          </div>
          <div className="relative mx-4">
            <h3 className="relative z-10 font-bold text-2xl text-black text-center">
              Looks like there are no tickets for this area.
            </h3>
            <FaceFrownIcon className="absolute -bottom-12 -right-8 sm:-right-[4.25rem] text-[#F9CF47] w-24 h-24 rotate-[15deg]" />
          </div>
        </div>
      </div>
    );
  }

  if (prices && prices.length > 0) {
    return (
      <EventGASelection
        basketTickets={basketTickets}
        eventId={eventId}
        eventName={eventName}
        eventVenueId={eventVenueId}
        orgUid={orgUid}
        showingId={showingId}
        prices={prices}
        analyticsConfigs={analyticsConfigs}
        landingPages={landingPages}
      />
    );
  }

  return <></>;
}
