import { Pricesection } from "@types";
import React from "react";
import { ArrowTrendingUpIcon } from "@heroicons/react/24/outline";
import { getCurrencyFormat } from "@utils/helpers";
import EventToolTip from "./eventToolTip";

interface Props {
  pricesection: Pricesection;
  priceschemeName: string;
}

interface CombinedArray {
  id: number;
  name: string;
  description: string;
  availability?: {
    startDate: string | null;
    endDate: string | null;
    formatted: string | null;
    local: {
      startDate: string | null;
      endDate: string | null;
      now: string;
    };
    offset: {
      start: number | null;
      end: number | null;
    };
  };
  price: number;
  isDynamic?: boolean;
}

const PriceSection = ({ pricesection, priceschemeName }: Props) => {
  const priceages = pricesection.priceages
    ?.filter(
      (pa) =>
        (pa.type === "standard" || pa.type === "extra") &&
        pa.active &&
        pa.display
    )
    .map((priceage) => ({
      id: priceage.id,
      name: priceage.name,
      description: priceage.description,
      availability: priceage.availability,
      display: priceage.display,
      price: priceage.price.price,
      isDynamic: priceage.price.type.id === 2,
      order: priceage.order,
    }))
    .sort((a, b) => a.order - b.order);

  const manualprices = pricesection.manualprices
    ?.filter((manualprice) => manualprice.active)
    .map((manualprice) => ({
      id: manualprice.id,
      name: manualprice.name,
      description: manualprice.description,
      price: manualprice.price,
      order: manualprice.order,
    }))
    .sort((a, b) => a.order - b.order);

  const combinedArrays: CombinedArray[] = [
    ...(priceages ?? []),
    ...(manualprices ?? []),
  ];

  if (!pricesection.priceages && !pricesection.manualprices) return null;
  if (combinedArrays.length <= 0) return null;

  function getPricingTense(availability?: {
    startDate: string | null;
    endDate: string | null;
    formatted: string | null;
    local: {
      startDate: string | null;
      endDate: string | null;
      now: string;
    };
  }) {
    if (!availability) return "present";
    if (
      availability.local.startDate &&
      availability.local.endDate &&
      availability.local.now >= availability.local.startDate &&
      availability.local.now <= availability.local.endDate
    ) {
      return "present";
    }
    if (
      availability.local.startDate &&
      !availability.local.endDate &&
      availability.local.now >= availability.local.startDate
    ) {
      return "present";
    }
    if (
      availability.local.endDate &&
      !availability.local.startDate &&
      availability.local.now <= availability.local.endDate
    ) {
      return "present";
    }
    if (
      availability.local.startDate &&
      availability.local.now < availability.local.startDate
    ) {
      return "future";
    }
    if (
      availability.local.endDate &&
      availability.local.now > availability.local.endDate
    ) {
      return "past";
    }
    return "present";
  }

  return (
    <div
      key={pricesection.id}
      className="flex gap-y-2 flex-col bg-indigo-100 rounded-2xl px-2 py-1"
    >
      <div className="shrink-0 flex-1 flex flex-col px-2 pt-1">
        <span className="text-xl text-indigo-600 font-extrabold">
          {priceschemeName.trim()}
          {priceschemeName && pricesection.name.trim() ? " - " : ""}
          {pricesection.name.trim()}
        </span>
        {pricesection.description && (
          <span className="text-gray-500">{pricesection.description}</span>
        )}
      </div>
      {combinedArrays?.map((price) => (
        <div
          key={price.id}
          className="flex items-center justify-between gap-4 sm:gap-6 px-2 py-1 rounded-xl text-indigo-600"
        >
          <div className="flex flex-col gap-1 w-full">
            <div className="flex justify-between items-center gap-2">
              <p className="font-medium text-sm">{price.name}</p>
              <span>{getCurrencyFormat(price.price)}</span>
            </div>
            {price.isDynamic ? (
              <span className="text-sm bg-iticket text-white px-2 py-0.5 font-semibold inline-flex items-center rounded-md gap-1 w-fit">
                <ArrowTrendingUpIcon className="h-4 w-4" />
                FLEXi
              </span>
            ) : (
              <></>
            )}
            {price.description && (
              <p className="text-sm leading-5 text-gray-500">
                {price.description}
              </p>
            )}
            {
              //re add tooltip when backend ready
              price.availability &&
              getPricingTense(price.availability) !== "present" ? (
                <span className="inline-flex items-center gap-1 text-sm bg-amber-100 text-amber-800 px-2 py-0.5 font-semibold rounded-md w-fit">
                  {getPricingTense(price.availability) === "past"
                    ? "No longer available"
                    : "Not yet available"}
                </span>
              ) : price.availability &&
                (price.availability.offset.start ||
                  price.availability.offset.end) ? (
                <span className="inline-flex items-center gap-1 text-sm bg-amber-100 text-amber-800 px-2 py-0.5 font-semibold rounded-md w-fit">
                  Not yet available
                </span>
              ) : (
                <></>
              )
            }
          </div>
        </div>
      ))}
    </div>
  );
};

export default PriceSection;
//
// <EventToolTip
//   title={
//     getPricingTense(price.availability) === "past"
//       ? "No longer available"
//       : "Not yet available"
//   }
//   description={
//     getPricingTense(price.availability) === "past"
//       ? `This price was available ${
//           !price.availability.startDate &&
//           price.availability.endDate
//             ? "until"
//             : "from"
//         } ${price.availability.formatted}`
//       : `This price will be available ${
//           price.availability.startDate &&
//           !price.availability.endDate
//             ? "from"
//             : "between"
//         } ${price.availability.formatted}`
//   }
//   isWarning
// />
//
// <EventToolTip
//   title="Not yet available"
//   description={`This price will be available ${
//     price.availability.offset.start &&
//     price.availability.offset.end
//       ? `between ${price.availability.offset.start} and ${price.availability.offset.end}`
//       : price.availability.offset.start ??
//         price.availability.offset.end
//   } days before each showing in the event.`}
//   isWarning
// />
